const HOST = process.env.FR_API_URL;

export { HOST };
export const API_URL = `${HOST}/api/v2/`;
export const API_V1_URL = `${HOST}/api/v1/`;
export const API_V2_URL = `${HOST}/api/v2/`;

let _token : String = "";
let _csrfToken : String = ""

export function getAuthorizationHeader() : String {
  return `Token token=${_token}`;
}

export function setAuthorizationToken(token: String) {
  _token = token;
}

export function getAuthorizationToken() : String {
  return _token;
}

export function getCSRFToken() : String {
    return _csrfToken
}

export function setCSRFToken(token : String) {
    _csrfToken = token;
}
