import React from "react"
import Modal from "react-bootstrap/lib/Modal"
import Button from "react-bootstrap/lib/Button"

function AlertModalWithBody(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------
  const {
    show,
    onHide,
    onConfirm,
    title,
    closeLabel,
    isTiny = true,
    shadow = false,
    secondActionLabel = null,
    onSecondAction = null,
    closeBsStyle = "primary",
    confirmDisabled= false
  } = props
  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  // -------------------------------------
  return (
    <Modal
      className={`modal ${isTiny ? "modal--tiny" : ""} ${
        shadow ? "modal--shadow" : ""
      }`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title style={{ maxHeight: "unset" }}>{title}</Modal.Title>
        <button
          className="close"
          aria-label="Close"
          data-dismiss="modal"
          type="button"
          onClick={onHide}
        >
          <i className="fal fa-times" />
        </button>
      </Modal.Header>

      <Modal.Body>{props.children}</Modal.Body>

      <Modal.Footer>
        {onSecondAction && (
          <Button bsStyle="default" onClick={onSecondAction}>
            {secondActionLabel}
          </Button>
        )}
        <Button bsStyle={closeBsStyle}
                disabled={confirmDisabled}
                onClick={onConfirm}>
          {closeLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AlertModalWithBody
