// ----------------------------------------------------------------------------
// Google APIs Utilities
// ----------------------------------------------------------------------------

let autocompleteToken = null
let googleAutocomplete = null
let googlePlaces = null
let googleGeocode = null

const REQUEST_TIMEOUT_MILLIS = 3000

const mapOptions = {
  types: ["address"], // Instructs the Place Autocomplete service to return only geocoding results with a precise address. Generally, you use this request when you know the user will be looking for a fully specified address.'
  componentRestrictions: { country: "it" },
  fields: ["address_component", "geometry", "formatted_address"],
}

// -------------------------------------

function initGoogleAPIs() {
  if (!google) {
    console.warn("GOOGLE: no google apis")
    return
  }

  googleAutocomplete = new google.maps.places.AutocompleteService()
  googlePlaces = new google.maps.places.PlacesService(
    document.createElement("div")
  )
  googleGeocode = new google.maps.Geocoder()
}

// -------------------------------------

export function initGoogleMapsIfNeeded(apiKey) {
  // append the google apis script only if it does not exists
  const googleScript = document.getElementById("google-script")
  if (!googleScript) {
    const script = document.createElement("script")

    script.id = "google-script"
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&languages=${I18n.locale}`
    script.async = true
    script.onload = initGoogleAPIs

    document.body.appendChild(script)
  }
}

export function getPlacePredictions(address) {
  return new Promise((resolve, reject) => {
    try {
      eval(google)
      if (google && googleAutocomplete) {
        if (!autocompleteToken) {
          autocompleteToken = new google.maps.places.AutocompleteSessionToken()
        }

        const params = {
          componentRestrictions: mapOptions.componentRestrictions,
          input: address,
          types: mapOptions.types,
          sessionToken: autocompleteToken,
        }
        googleAutocomplete.getPlacePredictions(
          params,
          function (predictions, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              resolve(predictions)
            } else {
              resolve(null)
            }
          }
        )
      } else {
        reject("getPlacePredictions ERROR")
      }
    } catch (e) {
      reject("getPlacePredictions ERROR: google not ready")
    }
  }, REQUEST_TIMEOUT_MILLIS)
}

export function getPlaceDetails(prediction) {
  return new Promise((resolve, reject) => {
    try {
      eval(google)
      if (google && googlePlaces && autocompleteToken && prediction.place_id) {
        const requestParams = {
          placeId: prediction.place_id,
          fields: mapOptions.fields,
          sessionToken: autocompleteToken,
        }
        googlePlaces.getDetails(requestParams, function (result, status) {
          autocompleteToken = null
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject("getPlaceDetails ERROR")
          } else {
            resolve(structureAddress(result))
          }
        })
      }
    } catch (e) {
      reject("getPlaceDetails ERROR: google not ready")
    }
  }, REQUEST_TIMEOUT_MILLIS)
}

export function getGeocode(query) {
  return new Promise((resolve, reject) => {
    try {
      eval(google)
      if (google && googleGeocode) {
        let request = {
          address: query,
         }
        googleGeocode.geocode(request, function (result, status) {
          autocompleteToken = null
          if (status !== 'OK') {
            reject("getGeocode ERROR")
          } else {
            resolve(structureAddress(result[0] || result))
          }
        })
      }
    } catch (e) {
      reject("getPlaceDetails ERROR: google not ready")
    }
  }, REQUEST_TIMEOUT_MILLIS)
}

// ----------------------------------------------------------------------------
// Format Google place result
// ----------------------------------------------------------------------------

export const ADDRESS_KEYS = {
  STREET_NUMBER: "street_number",
  STREET_NAME: "route",
  LOCALITY: "locality",
  CITY: "administrative_area_level_3",
  PROVINCE: "administrative_area_level_2",
  REGION: "administrative_area_level_1",
  COUNTRY: "country",
  POSTAL_CODE: "postal_code",
  FULL_ADDRESS: "full_address",
  LAT: "lat",
  LNG: "lng",
}

function structureAddress(address, isJson = false) {
  let formatted = {}
  formatted[ADDRESS_KEYS.FULL_ADDRESS] = address.formatted_address
  formatted[ADDRESS_KEYS.LAT] = isJson ? address.geometry.location.lat : address.geometry.location.lat()
  formatted[ADDRESS_KEYS.LNG] = isJson ? address.geometry.location.lng : address.geometry.location.lng()
  address.address_components.forEach((component) => {
    if (component.types.includes(ADDRESS_KEYS.STREET_NUMBER)) {
      formatted[ADDRESS_KEYS.STREET_NUMBER] = component.long_name
    } else if (component.types.includes(ADDRESS_KEYS.STREET_NAME)) {
      formatted[ADDRESS_KEYS.STREET_NAME] = component.long_name
    } else if (component.types.includes(ADDRESS_KEYS.LOCALITY)) {
      formatted[ADDRESS_KEYS.LOCALITY] = component.long_name
    } else if (component.types.includes(ADDRESS_KEYS.CITY)) {
      formatted[ADDRESS_KEYS.CITY] = component.long_name
    } else if (component.types.includes(ADDRESS_KEYS.PROVINCE)) {
      formatted[ADDRESS_KEYS.PROVINCE] = component.long_name
    } else if (component.types.includes(ADDRESS_KEYS.REGION)) {
      formatted[ADDRESS_KEYS.REGION] = component.long_name
    } else if (component.types.includes(ADDRESS_KEYS.COUNTRY)) {
      formatted[ADDRESS_KEYS.COUNTRY] = component.long_name
    } else if (component.types.includes(ADDRESS_KEYS.POSTAL_CODE)) {
      formatted[ADDRESS_KEYS.POSTAL_CODE] = component.long_name
    }
  })
  return formatted
}
