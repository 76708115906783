export const ADVANCE = "advance";
export const REPLACE = "replace";

export default class BrowserLocationHelper {
    static ChangeURL(url, action = ADVANCE) {
        if (window.Turbolinks) {
            Turbolinks.visit(url, {action: action })
        } else {
            if (typeof window.Spinner !== 'undefined') { Spinner.show(); }

            window.location = url;
        }
    }

    static ReloadPage(url) {
        BrowserLocationHelper.ChangeURL(window.location.href, REPLACE);
    }
}
