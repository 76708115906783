import React from "react"

import AlertModalWithBody from "../../ui-components/AlertModalWithBody"

import styles from "../RedeemGiftCard.module.scss"

// ----------------------------------------------------------------------------

export default function SuccessModal(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { giftCard, show, onHide, onConfirm, closeLabel } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  let amount = giftCard?.amount_cents

  // -------------------------------------

  return (
    <AlertModalWithBody
      show={show}
      onHide={onHide}
      onConfirm={onConfirm}
      title={I18n.t("pages.gift_cards.redeem.modal_title")}
      closeLabel={closeLabel}
    >
      <div className={styles.modalImage}></div>
      <p>
        {I18n.t("pages.gift_cards.redeem.modal_body", {
          amount: amount / 100,
        })}
      </p>
      <div className="fr-text-centered fr-margin-top-large">
        <p className="fr-h1 fr-strong fr-primary mb-0">{amount / 100}€</p>
        <p className="fr-p1-18 fr-normal">
          {I18n.t("pages.gift_cards.redeem.modal_body_2")}
        </p>
        {giftCard?.message && (
          <div
            className={`${styles.messageContainer} fr-italic fr-normal fr-bg-light-gray fr-margin-top-large`}
          >
            {giftCard.message.split("\n").map((m, i) => (
              <p key={`g-desc-${i}`} className="fr-p2 fr-normal">
                {m}
              </p>
            ))}
          </div>
        )}
      </div>
    </AlertModalWithBody>
  )
}
