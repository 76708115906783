import superagent from "superagent"
import { HOST } from "./base"

export default class InvitationsAPI {
  static async SendInvite(target, email) {
    const URL = `${target}`
    return await superagent
      .post(URL)
      .set("Content-Type", "multipart/form-data")
      .query({ users_invitation: { emails_field: email } })
  }
}
