import React from 'react'

export default class ShareUrlBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            justCopied: false
        };
    }

    handleCopyClicked = () => {

        this.refs.urlInput.select();
        document.execCommand("copy");

        this.setState({
            justCopied: true
        });

        setTimeout(() => {
            this.setState({
                justCopied: false
            });
        }, 2000);
    };

    handleInputChange = (event) => {
        event.preventDefault();
    };

    handleInputClicked = (event) => {
        event.preventDefault();

        this.refs.urlInput.focus();
        this.refs.urlInput.select();
        this.refs.urlInput.setSelectionRange(0, 1000);
    };

    render() {
        return (
            <div className="share-referral-url-box">
                <input ref="urlInput"
                       value={ this.props.url }
                       onChange={ this.handleInputChange }
                       onClick={ this.handleInputClicked } />
                <div className="share-referral-url-box__btn"
                     onClick={this.handleCopyClicked}>
                    { this.state.justCopied ? I18n.t('react.share_url_box.just_copied') : I18n.t('react.share_url_box.copy') }
                </div>
            </div>
        )
    }
}