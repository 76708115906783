import React, { useState, useEffect } from "react"

import * as api from "../../apis/OrdersAPI"
import { setAuthorizationToken } from "../../apis/base"
import CartStateStore, { PLACE_ORDER, useCartStateStore } from "../../stores/CartStateStore"

import style from "./index.module.sass"

// ----------------------------------------------------------------------------

const ORDER_STATUS = {
  PENDING_PAYMENT: "pending_payment",
  PAYMENT_FAILED: "payment_failed",
}

const MAX_TIME = 300
const DEADLINE_EXPIRED = "expired"

function _PaymentInProgressBanner(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { user_session_token } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [cartState, dispatchCartState] = useCartStateStore()

  const [pendingOrder, setPendingOrder] = useState(false)
  const [deadline, setDeadline] = useState(undefined)

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (!user_session_token) {
      return
    }

    // check if there is an order in a pending payment state
    let pendingOrderId = cartState.paymentInfo?.orderId
    if (!!pendingOrderId) {
      setAuthorizationToken(user_session_token)
      api
        .GetOrder(pendingOrderId)
        .then((res) => {
          if (!!res) {
            // if payment pending show banner
            switch (res.status) {
              case ORDER_STATUS.PENDING_PAYMENT:
                computeAndSetDeadline(res.payment?.expires_at)
                setPendingOrder(res)
                break
              case ORDER_STATUS.PAYMENT_FAILED:
                // nothing to do, clean localstorage
                dispatchCartState({ type: RESET_PAYMENT_INFO, payload: {} })
                break
              default:
                // empty cart
                dispatchCartState({
                  type: PLACE_ORDER,
                  payload: { venue_id: res.venue?.id, order: res },
                })
                break
            }
          }
        })
        .catch((err) => {
          // TODO: nothing to do?
        })
    }
    // if ! order ID do nothing: double ordering is blocked server side
  }, [])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function computeAndSetDeadline(expires_at) {
    let date = new Date(expires_at)
    let now = new Date()
    let diff = date - now
    if (diff > 0 && diff / 1000 < MAX_TIME) {
      let minutes = Math.floor(diff / 1000 / 60)
      let seconds = Math.floor((diff / 1000) % 60)

      setDeadline(`${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`)

      setTimeout(() => {
        computeAndSetDeadline(expires_at)
      }, 1000)
    } else {
      setDeadline(DEADLINE_EXPIRED)
    }
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  let destination = undefined
  if (pendingOrder.status === ORDER_STATUS.PAYMENT_FAILED) {
    destination = "/orders/" + pendingOrder.id
  } else if (pendingOrder.status === ORDER_STATUS.PENDING_PAYMENT) {
    destination = "/orders/" + pendingOrder.id + "/payment"
  }

  // -------------------------------------

  return (
    <div className={`${style.container} ${pendingOrder ? style.visible : ""}`}>
      <div className={style.inner}>
        <a className={style.linkContainer} href={destination}>
          <i className={`fal fa-exclamation-circle ${style.icon}`}></i>
          <p className={style.description}>
            {pendingOrder.status === ORDER_STATUS.PAYMENT_FAILED ||
            deadline === DEADLINE_EXPIRED
              ? I18n.t("order_payment.payment_failed_warn")
              : I18n.t("order_payment.payment_in_progress_warn", {
                  deadline: deadline,
                })}
          </p>
          {!deadline === DEADLINE_EXPIRED && (
            <div className={style.link}>
              {" "}
              {I18n.t("order_payment.payment_in_progress_warn_complete")}
            </div>
          )}
        </a>
      </div>
    </div>
  )
}

// ----------------------------------------------------------------------------

export default function PaymentInProgressBanner(props) {
  return (
    <CartStateStore>
      <_PaymentInProgressBanner {...props}></_PaymentInProgressBanner>
    </CartStateStore>
  )
}
