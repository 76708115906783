if (
  typeof onPageReady !== "undefined" ||
  typeof window.onPageReady !== "undefined"
) {
  onPageReady(function(event) {
    setTimeout(function() {
      $(".flash-bar").addClass("flash-bar--slideUp")
    }, 3000);
  });
}
