import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/lib/Button"

import BrowserLocationHelper from "../utils/browser_location_helper"
import { setAuthorizationToken } from "../apis/base"
import { redeemGiftCard } from "../apis/GiftCardsAPI"
import AlertModalWithBody from "../ui-components/AlertModalWithBody"
import SingleFieldFormWithValidation from "../ui-components/SingleFieldFormWithValidation"
import SuccessModal from "./SuccessModal"

import styles from "./RedeemGiftCard.module.scss"

// ----------------------------------------------------------------------------

export default function RedeemGiftCard(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { giftCard: propsGiftCard, token, btnDefault = false } = props

  const propsCode = propsGiftCard?.code

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [giftCard, setGiftCard] = useState({
    ...propsGiftCard,
    redeemModal: false,
  })
  const [code, setCode] = useState(propsCode || "")
  const [error, setError] = useState(null)

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    setAuthorizationToken(token)
  }, [])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function hideModal() {
    setGiftCard({ ...giftCard, redeemModal: false })
  }

  function hideErrorModal() {
    setError(null)
  }

  function redirectToLogin() {
    BrowserLocationHelper.ChangeURL(
      `/${I18n.locale}/user/sessions/new?return_to=${encodeURIComponent(
        document.URL
      )}`
    )
  }

  function redirectToHome() {
    BrowserLocationHelper.ChangeURL(`/${I18n.locale}`)
  }

  async function handleRedeem() {
    if (code.trim() === "") return

    let { response, errors } = await redeemGiftCard(code)

    if (errors?.[0]?.status === 401) {
      setError({
        message: I18n.t("pages.gift_cards.redeem.error.unauthorized"),
      })
      return
    }

    if (errors?.[0]?.status === 404) {
      setError({ message: I18n.t("pages.gift_cards.redeem.error.wrong_code") })
      return
    }

    if (errors) {
      setError({
        message: errors[0]
          ? errors[0]
          : I18n.t("pages.gift_cards.redeem.error.wrong_code"),
      })
      return
    }

    setGiftCard({ ...response, redeemModal: true })
  }

  function renderSuccessModal() {
    return (
      <SuccessModal
        giftCard={giftCard}
        show={giftCard?.redeemModal}
        onHide={hideModal}
        onConfirm={redirectToHome}
        closeLabel={I18n.t("pages.gift_cards.redeem.modal_cta")}
      />
    )
  }

  function renderErrorModal() {
    return (
      <AlertModalWithBody
        show={!!error}
        onHide={hideErrorModal}
        onConfirm={hideErrorModal}
        title={I18n.t("pages.gift_cards.redeem.error.title")}
        closeLabel={"Ok"}
      >
        <p>{error?.message}</p>
      </AlertModalWithBody>
    )
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  let amount = giftCard?.amount_cents

  // -------------------------------------

  if (!token) {
    return (
      <div className={`${styles.container} fr-margin-top-large`}>
        <Button bsStyle="primary" bsSize="lg" onClick={redirectToLogin}>
          {I18n.t("pages.gift_cards.redeem.go_to_login")}
        </Button>
      </div>
    )
  }

  if (giftCard?.redeemed || giftCard?.expired) {
    return renderSuccessModal()
  }

  if (propsCode) {
    // if code is present just show a button to redeem
    return (
      <div className={`${styles.container} fr-margin-top-large`}>
        <Button
          bsSize="lg"
          bsStyle={btnDefault ? "default" : "primary"}
          onClick={handleRedeem}
        >
          {I18n.t("pages.gift_cards.redeem.cta")}
        </Button>

        {renderSuccessModal()}
        {renderErrorModal()}
      </div>
    )
  }

  return (
    <div className={`${styles.container} fr-margin-top-large`}>
      <SingleFieldFormWithValidation
        type="text"
        value={code}
        setValue={(value) => {
          setCode(value)
        }}
        placeholder={I18n.t("pages.gift_cards.redeem.placeholder")}
        buttonStyle={btnDefault ? "default" : "primary"}
        buttonLabel={I18n.t("pages.gift_cards.redeem.cta")}
        onSubmit={handleRedeem}
        feedback={""}
      />

      {renderSuccessModal()}
      {renderErrorModal()}
    </div>
  )
}
