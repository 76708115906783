window.onPageReady = function(callback) {
  if (typeof Turbolinks !== "undefined") {
    var event = "turbolinks:load";

    $(document).on(event, function() {
      callback();
    });
  } else {
    $(document).ready(function() {
      callback();
    });
  }
};
