if (
  typeof onPageReady !== "undefined" ||
  typeof window.onPageReady !== "undefined"
) {
  onPageReady(function () {
    var header = $(".cd-absolute-header")
    var headerFixed = $(".cd-fixed-header")
    var waveSx = $(".cover-image-wave-sx")

    function hideFixedHeader() {
      if ($("body").hasClass("fixed-body")) return

      headerFixed.addClass("cd-fixed-header--negative-top")
    }

    function showFixedHeader() {
      headerFixed.removeClass("cd-fixed-header--negative-top")
    }

    function hideHeader() {
      header.hide()
    }

    function showHeader() {
      header.show()
    }

    function safeZone() {
      return waveSx.height() || header.height()
    }

    // -------------------------------------
    // Handle header on scroll behaviour
    // -------------------------------------
    $(window).on(
      "scroll",
      {
        previousTop: 0,
      },
      function () {
        var currentTop = $(window).scrollTop()

        // showLogoOrange(currentTop < this.previousTop)
        if (currentTop < safeZone()) {
          // we are on the top of the page
          hideFixedHeader()
        } else if (currentTop < this.previousTop) {
          // we are scrolling to the top of the page
          showFixedHeader()
        } else {
          // we are scrolling to the bottom of the page
          hideFixedHeader()
        }
        this.previousTop = currentTop
      }
    )

    // -------------------------------------
    // Handle menu on click behaviour
    // -------------------------------------
    $(".frs .cd-primary-nav-trigger").on("click", function () {
      $(".cd-menu-icon").toggleClass("is-clicked")
      header.toggleClass("menu-is-open")
      headerFixed.toggleClass("cd-fixed-header--animation")

      if ($(".cd-primary-nav").hasClass("is-visible")) {
        $(".cd-primary-nav").removeClass("is-visible")

        // restore body height
        $("body").removeClass("fixed-body")
        $("body").css("height", "inherit")
        $("body").css("overflow", "inherit")

        hideFixedHeader()
        showHeader()
      } else {
        $(".cd-primary-nav").addClass("is-visible")

        // limit body height to prevent scroll
        $("body").addClass("fixed-body")
        $("body").css("height", "100vh")
        $("body").css("overflow", "hidden")

        showFixedHeader()
        hideHeader()
      }
    })
  })
}
