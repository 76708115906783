import request from "superagent";
import { API_URL, getAuthorizationHeader } from "./base";

export const CreateOrder = (body, token) => {
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}orders`)
      .send(body)
      .set('Authorization', `Token token=${token}`)
      .set('Accept', 'application/json')
      .then(res => {
        resolve(res.body.result);
      })
      .catch(error => {
        reject(error);
    })
  });
}

export const GetOrder = (id) => {
  return new Promise((resolve, reject) => {
    request
      .get(`${API_URL}orders/${id}`)
      .set('Authorization', getAuthorizationHeader())
      .set('Accept', 'application/json')
      .end((error, res) => {
        error ? reject(error) : resolve(res.body.result);
      });
  });
}

export const RequestRejectOrder = (id) => {
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}orders/${id}/reject_request`)
      .set('Authorization', getAuthorizationHeader())
      .set('Accept', 'application/json')
      .then(res => {
        resolve(res.body.result);
      })
      .catch(error => {
        reject(error);
      })
  });
}

export const GetOrderPayment = (id) => {
  return new Promise((resolve, reject) => {
    request
      .get(`${API_URL}orders/${id}/payment`)
      .set('Authorization', getAuthorizationHeader())
      .set('Accept', 'application/json')
      .end((error, res) => {
        error ? reject(error) : resolve(res.body.result);
      });
  });
}

export const ValidateCart = (lineItems) => {
  return new Promise((resolve, reject) => {
    const body = { order: { line_items: lineItems }};

    request
      .post(`${API_URL}shopping_carts/validate`)
      .send(body)
      .set('Authorization', getAuthorizationHeader())
      .set('Accept', 'application/json')
      .then(res => {
        resolve(true);
      })
      .catch(error => {
        if (error && error.response) {
          reject(error.response.body);
        } else {
          reject(["Unknown error"])
        }
      })
  });
}

export const getOrderGameUrl = (userId, orderId, token) => {
  return request
    .get(`${API_URL}games/url/il-piatto-e-servito`)
    .set("Accept", "application/json")
    .set("Authorization", `Token token=${token}`)
    .query({ user_id: userId, order_id: orderId })
}
