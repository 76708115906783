export function registerOrderToFacebook(order) {
  try {
    const price = order.total_price_cents / 100
    fbq("track", "Purchase", { value: price.toString(), currency: "EUR" })
  } catch (err) {
    // console.log("Unable to send to Facebook");
  }
}

export function registerAddToCartToFacebook(price) {
  try {
    fbq("track", "AddToCart", { value: price.toString(), currency: "EUR" })
  } catch (err) {
    // console.log("Unable to send to Facebook");
  }
}

export function registerBeginCheckoutToFacebook(totalPrice) {
  try {
    fbq("track", "InitiateCheckout", {
      value: totalPrice.toString(),
      currency: "EUR",
    })
  } catch (err) {
    // console.log("Unable to send to Facebook");
  }
}

export function registerSignupToFacebook() {
  try {
    fbq("track", "CompleteRegistration", { value: "1", currency: "EUR" })
  } catch (err) {
    // console.log("Unable to send to Facebook");
  }
}
