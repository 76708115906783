import request from "superagent"
import { API_URL, getAuthorizationHeader } from "./base"

export function redeemGiftCard(code) {
  const URL = `${API_URL}/gift_cards/redeem`

  return new Promise((resolve, reject) => {
    request
      .post(URL)
      .set("Authorization", getAuthorizationHeader())
      .query({ code })
      .end((error, res) => {
        if (!error && res.ok) {
          resolve({ response: res.body.result, error: null })
        } else {
          resolve({ response: null, errors: res?.body?.full_error_messages || [error] })
        }
      })
  })
}
