import React from "react";
import Cookies from "js-cookie";

export default class DeleteProfilingCookies extends React.Component  {
  handleDeleteCookies = (event) => {
    if (event) { event.preventDefault();}

    const { cookies, domain} = this.props;

    cookies.map((cookie) => {
      Cookies.remove(cookie, { domain: domain});
    })

    alert('Abbiamo eliminato il cookie di consenso, ricarica la pagina per rendere effettiva la richiesta')
  }

  render() {
    return <a onClick={this.handleDeleteCookies} href="#0">Cancella i cookie di consenso</a>;
  }
}
