import React, { useReducer } from "react"
import InvitationsAPI from "../apis/InvitationsAPI"
import SingleFieldFormWithValidation from "../ui-components/SingleFieldFormWithValidation"

import style from "./InviteFriendForm.module"

// ----------------------------------------------------------------------------

const successMessage = I18n.t("invitations.pages.index.success")
const errorMessage = I18n.t("invitations.pages.index.cannot_send")

const initialState = { email: "", success: false, error: false }

function reducer(state, action) {
  switch (action.type) {
    case "set_email":
      return { email: action.payload }
    case "set_success":
      return { email: "", success: true, error: false }
    case "set_error":
      return { success: false, error: true }
    default:
      return state
  }
}

function InviteFriendForm(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { discountValue = "5€", url, target } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [state, dispatch] = useReducer(reducer, initialState)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function onSend() {
    try {
      const res = await InvitationsAPI.SendInvite(target, state.email)
      dispatch({ type: "set_success" })
    } catch (error) {
      dispatch({ type: "set_error" })
    }
  }

  function onCopy() {
    navigator.clipboard.writeText(url)
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  // -------------------------------------

  return (
    <div className={props.className}>
      <div className={`${style.standardContainer}`}>
        <div className={`${style.standardContainer} p-5`}>
          <h2 className={"fr-h2 fr-strong"}>
            {I18n.t("invitations.pages.index.type_friends_emails")}
          </h2>
          <p className={"fr-p2 fr-normal fr-light"}>
            {I18n.t("invitations.pages.index.subtitle", {
              discount_value: discountValue,
            })}
          </p>
        </div>
        <SingleFieldFormWithValidation
          type="email"
          value={state.email}
          setValue={(value) => {
            dispatch({ type: "set_email", payload: value })
          }}
          placeholder={I18n.t("invitations.pages.index.type_friends_emails")}
          buttonStyle="primary"
          buttonLabel={I18n.t("invitations.pages.index.send_emails")}
          onSubmit={onSend}
        />

        <div
          className="col-sm-12"
          style={{
            maxWidth: 600,
            display: state.error || state.success ? "unset" : "none",
          }}
        >
          <p
            className={`alert small ${
              state.error ? "alert-danger" : "alert-success"
            }`}
          >
            {state.error ? errorMessage : successMessage}
          </p>
        </div>
      </div>

      <div className={`${style.standardContainer}`}>
        <div className={`${style.standardContainer} ${style.extraMargin}`}>
          <p className={style.textStrong}>
            {I18n.t("invitations.pages.index.share_link")}
          </p>
        </div>
        <SingleFieldFormWithValidation
          value={url}
          readOnly
          feedback={I18n.t("invitations.pages.index.copy_link_feedback")}
          buttonStyle="default"
          buttonLabel={I18n.t("invitations.pages.index.copy_link")}
          onSubmit={onCopy}
        />
      </div>
    </div>
  )
}

// ----------------------------------------------------------------------------

export default InviteFriendForm
