import { EventEmitter } from 'events'
import AppDispatcher from '../dispatcher/AuthenticationAppDispatcher'
import FluxAuthenticationConstants from '../constants/FluxAuthenticationConstants'

let _current_user = window ? window.currentUser : undefined;

function setUser(user) {
    _current_user = user;

    // TODO: Get a better way to do this
    if (window) {
        window.currentUser = user ? { id: user.id } : null;
    }
}

const CurrentUserStore = Object.assign({}, EventEmitter.prototype, {

    getUser: function() {
        return _current_user;
    },

    // Emit Change event
    emitChange: function() {
        this.emit('change');
    },

    // Add change listener
    addChangeListener: function(callback) {
        this.on('change', callback);
    },

    // Remove change listener
    removeChangeListener: function(callback) {
        this.removeListener('change', callback);
    }
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
    const action = payload.action;

    switch (action.actionType) {

        // Respond to LOGGED action
        case FluxAuthenticationConstants.LOGGED:
            setUser(action.user);
            break;

        // Respond to LOGGED action
        case FluxAuthenticationConstants.UNAUTHORIZED:
            setUser(null);
            break;

        default:
            return true;
    }

    // If action was responded to, emit change event
    CurrentUserStore.emitChange();

    return true;
});

export default CurrentUserStore;
