import React from 'react'

import CurrentUserStore from '../stores/CurrentUserStore';

export default class SignInUserLink extends React.Component {
    /*static propTypes = {
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired
    };*/

    constructor(props) {
        super(props);
        this.state = {
            currentUser: null
        };
        this._handleCurrentUserStoreChange = this._handleCurrentUserStoreChange.bind(this);
    }

    componentDidMount() {
        CurrentUserStore.addChangeListener(this._handleCurrentUserStoreChange);
    }

    componentWillUnmount() {
        CurrentUserStore.addChangeListener(this._handleCurrentUserStoreChange);
    }

    _handleCurrentUserStoreChange() {
        this.setState( { currentUser: CurrentUserStore.getUser() });
    }

    render() {
        const label = this.state.currentUser ? this.state.currentUser.first_name : this.props.label;
        return (
            <a href={this.props.path}>{label}</a>
        )
    }
}
