import React, { useState, useRef, useEffect } from "react"
import Button from "react-bootstrap/lib/Button"
import FormGroup from "react-bootstrap/lib/FormGroup"
import FormControl from "react-bootstrap/lib/FormControl"
import InputGroup from "react-bootstrap/lib/InputGroup"
import Overlay from "react-bootstrap/lib/Overlay"
import Tooltip from "react-bootstrap/lib/Tooltip"

import style from "./index.module"

// ----------------------------------------------------------------------------

const VALUE_ERRORS = {
  empty: "empty",
  badFormat: "badFormat",
  tooManyEmails: "tooManyEmails",
}
const valueErrors = {
  [VALUE_ERRORS.empty]: I18n.t("react.components.empty_field"),
  [VALUE_ERRORS.badFormat]: I18n.t("react.components.wrong_field"),
  [VALUE_ERRORS.tooManyEmails]: I18n.t("react.components.too_many_fields"),
}

function SingleFieldFormWithValidation(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const {
    type = "text",
    value,
    setValue,
    placeholder = "",
    buttonStyle,
    buttonLabel,
    onSubmit,
    readOnly = false,
    feedback = undefined,
  } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [emailError, setEmailError] = useState(undefined)
  const [showFeedback, setShowFeedback] = useState(false)
  const formRef = useRef()

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (showFeedback) {
      setTimeout(() => {
        setShowFeedback(false)
      }, 2000)
    }
  }, [showFeedback])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function isEmail() {
    return type === "email"
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  function handleEnterPressed(event) {
    if (event.keyCode === 13) {
      handleSubmit()
      event.preventDefault()
    }
  }

  function handleSubmit() {
    if (value?.trim() === "") {
      setEmailError(VALUE_ERRORS.empty)
    } else if (isEmail() && !validateEmail(value || "")) {
      if (value?.includes(",") || value?.includes(" ")) {
        setEmailError(VALUE_ERRORS.tooManyEmails)
      } else {
        setEmailError(VALUE_ERRORS.badFormat)
      }
    } else {
      onSubmit()
      if (feedback && !showFeedback) {
        setShowFeedback(true)
      }
    }
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const inputStyle = readOnly
    ? { backgroundColor: "inherit", borderRight: "unset", boxShadow: "none" }
    : {}
  const buttonClass = readOnly ? style.readOnlySubmitButton : style.submitButton

  // -------------------------------------

  return (
    <form className={`${props.className} ${style.inputForm}`}>
      <FormGroup
        controlId="formBasicText"
        ref={formRef}
        // validationState={this.getValidationState()}
      >
        <InputGroup>
          <FormControl
            type="text"
            style={inputStyle}
            value={value}
            placeholder={placeholder}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => handleEnterPressed(e)}
            readOnly={readOnly}
          />
          <InputGroup.Button>
            <Button
              bsStyle={buttonStyle}
              className={buttonClass}
              onClick={handleSubmit}
            >
              {buttonLabel}
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </FormGroup>
      {(isEmail() || (feedback !== null && feedback !== undefined)) && formRef && (
        <Overlay
          rootClose={true}
          show={!!emailError || showFeedback}
          target={formRef.current}
          onHide={() => setEmailError(undefined)}
          placement="bottom"
        >
          <Tooltip id="warning-message">
            {valueErrors[emailError] || feedback}
          </Tooltip>
        </Overlay>
      )}
    </form>
  )
}

// ----------------------------------------------------------------------------

export default SingleFieldFormWithValidation
